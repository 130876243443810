import React, { useState, useEffect } from "react";
import ModalMoopian from "../components/ModalMoopian";
import Header from "../components/Header";
import axios from "axios";

const ImageLoader = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);

  // const convertedUrl = src.replace(
  //   "gray-total-ferret-825.mypinata.cloud",
  //   "ipfs.io"
  // );

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="relative">
      {isLoading && (
        <div className="h-[220px] w-full flex items-center">
          <img src="/img/Logo.png" alt={""} className="rounded-2xl" />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        onLoad={handleLoad}
        className={
          isLoading
            ? "hidden"
            : "block h-[220px] w-full object-cover rounded-2xl"
        }
      />
    </div>
  );
};

export default function UserDashboard(props) {
  let loginUserAddress = sessionStorage.getItem("address");
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [userBalance, setBalance] = useState(0);
  // eslint-disable-next-line
  const [itemFeatured, setItemFeatured] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const regex = new RegExp(search, 'i');
  const filteredMoopians = itemFeatured.filter((data) =>
    regex.test(data.name)
  );

  const getNFTs = async () => {
    setLoading(true);
    if (loginUserAddress) {
      await axios({
        method: "get",
        url: `https://app.themoopians.io/api/moopians/${loginUserAddress}`,
        // url: `https://app.themoopians.io/api/moopians/0x1830C9B1A7a0B411B90f06bf13965cd6c29E317a`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          console.log(response.data);
          await setItemFeatured(response.data);
          setLoading(false);
        })
        .catch(async (error) => {
          console.log(error.response);
          setLoading(false);
        });
    }
  };

  const handleModal = (data) => {
    setIsOpen(true);
    setModalData(data);
  };

  const getBalance = async (address) => {
    await axios({
      method: "get",
      url: `https://app.themoopians.io/api/getBalance/${address}`,
      // url: `http://localhost:8081/getBalance/0xa6E272F6942Dd501D72AfbCE4B128Bb0D62a41Eb`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        console.log(response.data.data);
        if (response.data.data) setBalance(response.data.data);
        else setBalance(0);
      })
      .catch(async (error) => {
        console.log(error.response);
        setBalance(0);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    getNFTs();
  }, []);

  useEffect(() => {
    getBalance(loginUserAddress);
  }, []);

  return (
    <>
      <div className="font-Poppins flex justify-end max-w-[1920px] mx-auto">
        <div className="bg-[#F8F8F8] min-h-screen w-full lg:w-[80%] 2xl:w-[85%] dark:bg-slate-700 pt-4 sm:pt-8 px-4 sm:px-8 space-y-8">
          <Header userBalance={userBalance} search={search} setSearch={setSearch} placeholder="Search Moopians.." />

          <div className="bg-[#F2D8CC] rounded-xl lg:min-h-[254px] min-h-[154px] flex justify-between items-center pt-6 px-6 sm:p-[60px] relative">
            <div className="space-y-4 w-full md:w-[55%] max-md:relative max-md:flex flex-col">
              {/*<div className="w-[132px] absolute -right-5 -top-5 md:hidden">
                <img src="/img/image 2.png" alt="" />
              </div> */}
              <h2 className="text-2xl leading-[120%] md:text-[54px] sm:w-full font-semibold">
                Welcome Back!
              </h2>
              {/* <h4 className="md:text-lg font-semibold leading-7">
                Featured Benefit
              </h4>
              <p className="text-[#4F4F4F] max-md:text-sm">
                Treat yourself to the decadent taste of ice-cream. Simply flash
                your Moopian at the nice folks at Baskin Robbin and get a random
                flavour for free!
              </p>
              <button className="md:text-lg text-[#E6007C] font-medium py-3 px-4 bg-white rounded-lg w-full md:w-1/2">
                Learn More
              </button>
              <div className="w-[211px] md:hidden self-center">
                <img src="/img/image 1.png" alt="" />
              </div> */}
            </div>

            {/* <div className="max-md:hidden w-[30%] flex flex-col items-end absolute right-[60px] bottom-0">
              <div className="w-[132px]">
                <img src="/img/image 2.png" alt="" />
              </div>
              <div className="w-[211px] bottom-0">
                <img src="/img/image 1.png" alt="" />
              </div>
            </div> */}
          </div>

          <div>
            <div className="w-full gap-4 flex justify-between flex-wrap sm:flex-nowrap sm:items-center">
              <h2 className="text-[#27262E] text-2xl font-semibold">
                My Featured Moopian
              </h2>
            </div>

            {itemFeatured.length ? (
              <div className="flex max-sm:justify-center flex-wrap gap-y-6 sm:gap-2 md:gap-3 lg:gap-6 my-6">
                {filteredMoopians.length
                  ? filteredMoopians.map((data, index) => (
                    <div
                      key={index}
                      className="rounded-2xl bg-white p-3 space-y-6 w-full sm:w-[49%] md:w-[32%] lg:w-[30%] 2xl:w-[32%]"
                      onClick={() => handleModal(data)}
                    >
                      <div className="w-full rounded-xl max-sm:flex items-center justify-center">
                        <ImageLoader src={data.image} alt={data.heading} />
                      </div>

                      <div className="ml-3 space-y-[6px] pb-4">
                        <h2 className="text-2xl text-[#27262E] font-semibold">
                          {data.name}
                        </h2>
                        <p className="text-[#747475] overflow-hidden line-clamp-4">{data.description}</p>
                      </div>
                    </div>
                  ))
                  : <div className="text-center w-full">No Moopians found</div>}
              </div>
            ) : (
              <div className="flex w-full mt-12 justify-between">
                <div className="space-y-6 py-5 max-sm:w-full">
                  <p className="text-lg font-medium">
                    Oh no, it seems like you don't have a Moopian.. for now.
                  </p>
                  <p className="text-[#4F4F4F]">
                    Visit Siam Square Mookata to get your first Moopian today!
                  </p>
                  {/* <div className="sm:hidden flex justify-center">
                    <img className="w-[129px]" src="/img/image 3.png" alt="" />
                  </div> */}
                  <button className="text-lg text-white font-medium py-3 px-4 bg-[#E6007C] rounded-lg w-full sm:w-3/4">
                    Learn More
                  </button>
                </div>

                {/* <div className="w-[40%] max-sm:hidden">
                  <img className="w-[412px]" src="/img/image 3.png" alt="" />
                </div> */}
              </div>
            )}
          </div>
        </div>

        {isOpen && modalData && (
          <ModalMoopian data={modalData} closeModal={closeModal} />
        )}
      </div>
      {loading
        ? <div className="fixed  right-0 top-0  transform flex justify-center items-center w-full min-h-screen z-50 bg-[rgba(0,0,0,0.2)]">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-[#E6007C] border-8 h-52 w-52"></div>
        </div>
        : null
      }
    </>
  );
}
