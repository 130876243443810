import React from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

export default function DetailsMoopian(props) {
  const convertedUrl = props.data.image.replace(
    "gray-total-ferret-825.mypinata.cloud",
    "ipfs.io"
  );
  return (
    <>
      <div className="mx-5">
        <div className="flex flex-col lg:flex-row h-full w-full gap-x-8">
          <div className="lg:max-w-[500px] lg:h-[500px] w-full">
            <p className="mb-5 flex space-x-3 font-semibold items-center cursor-pointer" onClick={() => props.setIsDetail(false)}>
              <MdOutlineKeyboardBackspace />
              <span>Back</span>
            </p>
            <img
              src={convertedUrl}
              alt=""
              className="rounded-lg w-full h-full"
            />
          </div>

          <div className="lg:w-[70%] mt-10 space-y-6">
            <div className="space-y-5">
              <h1 className="text-2xl md:text-[40px] font-semibold">
                {props.data.name}
              </h1>

              <p className="text-[#747475] text-sm md:text-base">
                <span className="line-clamp-2">
                  {props.data.description}
                </span>
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="13"
                  viewBox="0 0 17 13"
                  fill="none"
                >
                  <path
                    d="M15.8261 6.42277C15.8617 6.46812 15.8617 6.53197 15.8261 6.57731L11.501 12.0771C11.4773 12.1072 11.4411 12.1248 11.4028 12.1248L1 12.125C0.930965 12.125 0.875 12.069 0.875 12L0.875 1.0001C0.875 0.931066 0.930965 0.875102 0.999998 0.875101L11.4028 0.875005C11.4411 0.875004 11.4773 0.892599 11.501 0.922737L15.8261 6.42277Z"
                    stroke="black"
                    strokeWidth="1.75"
                    strokeLinejoin="round"
                  />
                </svg>
                <h3 className="text-lg font-semibold">Traits</h3>
              </div>

              <div className="flex flex-wrap gap-4 max-h-[62vh] overflow-scroll scroll-hide">
                {props.data.attributes.map((data, index) => (
                  <div
                    key={index}
                    className="w-[48%] lg:w-[30%] w-full bg-white border-[0.5px] rounded-xl"
                  >
                    <div className="text-xs sm:text-sm w-full text-[#27262E] font-medium text-center space-y-3 py-3">
                      <p>{data.trait_type}</p>
                      <p className="text-[#E6007C] font-semibold">
                        {data.value}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
