import React, { useState, useEffect } from "react";
import { sequence } from "0xsequence";
import { useNavigate } from "react-router-dom";

const walletAppURL =
  process.env.REACT_APP_WALLET_APP_URL || "https://sequence.app";
const network = "polygon";
sequence.initWallet(network, { walletAppURL });

export default function SignIn() {
  const navigate = useNavigate();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [userAddress, setAddress] = useState("");

  const wallet = sequence.getWallet();

  useEffect(() => {
    statusSet();
  }, [wallet]);

  const statusSet = async () => {
    setIsWalletConnected(wallet.isConnected());
    if (wallet.isConnected()) {
      const address = await wallet.getAddress();
      await setAddress(address);
    }
  };

  const connect = async () => {
    if (isWalletConnected) {
      await disconnect();
    }
    try {
      console.log("Connecting...");
      const wallet = sequence.getWallet();
      const connectDetails = await wallet.connect({
        app: "The Moopians",
        authorize: true,
        keepWalletOpened: false,
        // And pass settings if you would like to customize further
        settings: {
          theme: "light",
          bannerUrl: "https://app2.themoopians.io/img/Banner.png", // 3:1 aspect ratio, 1200x400 works best
          includedPaymentProviders: ["moonpay", "ramp"],
          defaultFundingCurrency: "matic",
          lockFundingCurrencyToDefault: false,
        },
      });
      if (connectDetails.connected) {
        console.log("Wallet Connected!");
        setIsWalletConnected(true);
        const address = await wallet.getAddress();
        await setAddress(address);
        sessionStorage.setItem("address", address);
        console.log("users address:", address);
        navigate("/dashboard");
      } else {
        console.log("Failed to connect wallet!");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const disconnect = () => {
    const wallet = sequence.getWallet();
    wallet.disconnect();
    console.log("Wallet Disconneted!");
    setIsWalletConnected(false);
    setAddress("");
  };

  return (
    <>
      <div className="min-h-[100vh] bgImage bg-cover bg-fixed">
        {/* <div className="bgImageInner min-h-[100vh] bg-[#E6007C4D] relative"></div> */}
      </div>
      <div className="absolute top-0 w-full">
        <div className="container mx-auto min-h-[100vh] ">
          <div className="flex items-center flex-wrap justify-between min-h-[100vh]">
            <div className="lg:w-[30%] md:w-[50%] w-full px-3 bg-transparent lg:mt-0 md:mt-0 mt-4 min-h-[90vh] rounded-2xl text-center">
              <img
                src="/img/the-moopians.svg"
                className="w-[360px] mx-auto py-16"
                alt=""
              />
              <div className="min-h-[60vh] flex justify-center items-center flex-col space-y-10">
                <p className="text-3xl font-bold text-[#E6007C]">
                Join the Adventure
                </p>
                <p className="text-xl text-[#E6007C] font-normal">Your experience is their story</p>
                <button
                  className="text-white bg-[#8e1f66] rounded-lg py-3 lg:px-10 px-8 font-medium text-lg transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 flex items-center justify-center space-x-4"
                  onClick={() => connect()}
                >
                  <img src="/img/Sequence Isotype.svg" className="w-7 inline-block " alt="" />
                  <span>Login with Sequence</span>
                </button>
                <a
                  className="text-white bg-[#8e1f66] rounded-lg py-3 px-10 font-medium text-lg transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 flex items-center justify-center space-x-4"
                  href="https://opensea.io/collection/the-moopians" target="_blank" rel="noreferrer"
                >
                  <img src="/img/Logomark-Transparent White.svg" className="w-7 inline-block " alt="" />
                  <span>See the Collection</span>
                </a>
              </div>
            </div>

            <div className="lg:w-[60%] md:w-[50%] w-[100%] p-10">
              <div
                id="carouselExampleCaptions"
                className="relative"
                data-te-carousel-init
                data-te-carousel-slide
              >
                <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
                  <div
                    className="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                    data-te-carousel-active
                    data-te-carousel-item
                    style={{ backfaceVisibility: "hidden" }}
                  >
                    <div className="mb-10 hidden py-5 text-center text-[#E6007C] md:block">
                      <h5 className="text-[40px] font-bold pb-16">
                        Welcome to a place where colour and creativity reigns
                      </h5>
                      <p className="text-center font-bold text-lg max-w-[420px] mx-auto pb-10">
                        Spark your imagination and deck your Moopians out in their favorite clothes being a fashionista matters to these little adventurers!
                      </p>
                    </div>
                  </div>
                  <div
                    className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                    data-te-carousel-item
                    style={{ backfaceVisibility: "hidden" }}
                  >
                    <div className="mb-10 hidden py-5 text-center text-[#E6007C] md:block">
                      <h5 className="text-[40px] font-bold pb-16">
                        Welcome to a place where colour and creativity reigns
                      </h5>
                      <p className="text-center font-bold text-lg max-w-[420px] mx-auto pb-10">
                        Spark your imagination and deck your Moopians out in their favorite clothes being a fashionista matters to these little adventurers!
                      </p>
                    </div>
                  </div>
                  <div
                    className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                    data-te-carousel-item
                    style={{ backfaceVisibility: "hidden" }}
                  >
                    <div className="mb-10 hidden py-5 text-center text-[#E6007C] md:block">
                      <h5 className="text-[40px] font-bold pb-16">
                        Welcome to a place where colour and creativity reigns
                      </h5>
                      <p className="text-center font-bold text-lg max-w-[420px] mx-auto pb-10">
                        Spark your imagination and deck your Moopians out in their favorite clothes being a fashionista matters to these little adventurers!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
