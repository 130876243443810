import React, { useState } from "react";
import axios from "axios";

const ImageLoader = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);

  // const convertedUrl = src.replace(
  //   "gray-total-ferret-825.mypinata.cloud",
  //   "ipfs.io"
  // );

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="relative">
      {isLoading && (
        <div className="w-[295px] h-[347px] md:w-[584px] md:h-[534px] flex items-center">
          <img src="/img/Logo.png" alt={""} className="rounded-2xl" />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        onLoad={handleLoad}
        className={
          isLoading
            ? "hidden"
            : "block w-[295px] h-[347px] md:w-[584px] md:h-[534px] object-cover rounded-2xl"
        }
      />
    </div>
  );
};

export default function ModalMoopian(props) {
  const handleDownload = async () => {
    try {
      const convertedUrl = props.data.image.replace(
        "gray-total-ferret-825.mypinata.cloud",
        "ipfs.io"
      );
      const response = await axios.get(convertedUrl, {
        responseType: "blob",
      });

      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", "image.png");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      props.closeModal();
    } catch (error) {
      console.error("Error downloading media:", error);
    }
  };

  return (
    <>
      <div
        onClick={props.closeModal}
        className="fixed z-50 top-0 left-0 w-screen h-screen bg-black bg-opacity-40 flex justify-center items-center"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="p-[18px] md:p-8 bg-white rounded-2xl space-y-4 md:space-y-7"
        >
          <div className="rounded-xl">
            <ImageLoader src={props.data.image} alt={props.data.heading} />
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center max-md:gap-y-4">
            <h2 className="text-lg md:text-2xl font-semibold text-[#27262E] max-md:self-start">
              {props.data.name}
            </h2>
            <button
              onClick={handleDownload}
              className="text-lg font-medium text-white bg-[#E6007C] rounded-lg px-4 py-3 flex items-center justify-center gap-x-2 max-md:w-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M6.5 20H18.5M12.5 4V16M12.5 16L16 12.5M12.5 16L9 12.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Save Image</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
