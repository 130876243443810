import "./App.css";
import Inventory from "./pages/Inventory";
import Moopians from "./pages/Moopians";
import Shop from "./pages/Shop";
import SignIn from "./pages/SignIn";
import UserDashboard from "./pages/UserDashboard";
import { Routes, Route } from "react-router-dom";
import axios from "axios";

function App() {

  return (
    <>
      <Routes>
        <Route exact path="/dashboard" element={<UserDashboard />}></Route>
        <Route exact path="/moopians" element={<Moopians />}></Route>
        <Route exact path="/inventory" element={<Inventory />}></Route>
        {/* <Route exact path="/shop" element={<Shop getBalance={getBalance} />}></Route> */}
        <Route exact path="/" element={<SignIn />}></Route>
      </Routes>
    </>
  );
}

export default App;
