import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import axios from "axios";
import { LiaAngleDownSolid } from "react-icons/lia";

export default function Inventory(props) {
  let loginUserAddress = sessionStorage.getItem("address");
  const dropdownRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(7);
  const [selectedUserItem, setSelectedUserItem] = useState(0);
  const [itemFeatured, setItemFeatured] = useState([]);
  const [selectedItemFeatured, setSelectedItemFeatured] = useState(0);
  const [dropdown, setdropdown] = useState(false);
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [itemsShop, setItemsShop] = useState([]);
  const [filteredItemsShop, setFilteredItemsShop] = useState([]);
  const [userItems, setUserItems] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [values, setValues] = React.useState({
    nft: '',
    image: '',
    attributes: '',
  });
  const [userBalance, setBalance] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const regex = new RegExp(search, "i");
    const filteredItems = itemsShop.filter((item) =>
      regex.test(item.layer) || item.trait.some(trait => regex.test(trait.trait))
    );
    setFilteredItemsShop(filteredItems);
  }, [itemsShop, search]);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  useEffect(() => {
    if (filteredItemsShop.length > 0) {
      let index = filteredItemsShop.findIndex(item => item.layer === filteredItemsShop[filteredItemsShop.length - 1].layer);
      setSelectedItem(index);
    }
  }, [filteredItemsShop]);

  function getIndexByTraitType(attr, traitType) {
    for (let i = 0; i < attr.length; i++) {
      if (attr[i].trait_type === traitType) {
        return i;
      }
    }
    return -1;
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setdropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const setAttributes = async (value, layer) => {
    let attr = values.attributes;
    let index = getIndexByTraitType(attr, layer);
    if (index === 4) attr[15].value = value;
    if (index === 15) attr[4].value = value;
    attr[index].value = value;
    await setValues({ ...values, attributes: attr });
    await generateNft();
  };

  const saveNft = async () => {
    await setLoading(true);
    await axios
      .post(`https://app.themoopians.io/api/saveImage`, {
        attributes: values.attributes,
        nft: values.nft,
        description: itemFeatured[selectedItemFeatured].description,
      })
      .then(async (res) => {
        console.log(res);
        if (res.data.success) {
          setSuccess("Save NFT Succesfully");
          await axios
            .get(`https://metadata.sequence.app/tokens/polygon/0xC8099cD6f4e4D0049c4CD85834013D97D6F224d6/${values.nft}/refresh`)
            .then((res) => {
              console.log(res);
            });
        }
        else setError("Something wrong!!");
        setTimeout(() => {
          setSuccess("");
          setError("");
        }, 5000);
        await setLoading(false);
      });
  };

  const generateNft = async () => {
    await setLoading(true);
    await axios
      .post(`https://app.themoopians.io/api/generateImage`, {
        // .post(`http://localhost:8081/generateImage`, {
        attributes: values.attributes,
      })
      .then(async (res) => {
        if (res.data.success) {
          await setValues({ ...values, image: res.data.data });
          setSuccess("Generate NFT Succesfully");
        } else setError("Something wrong!!");
        setTimeout(() => {
          setSuccess("");
          setError("");
        }, 5000);
        await setLoading(false);
      });
  };

  const getNFTs = async () => {
    if (loginUserAddress) {
      await axios({
        method: "get",
        url: `https://app.themoopians.io/api/moopians/${loginUserAddress}`,
        // url: `https://app.themoopians.io/api/moopians/0xa6E272F6942Dd501D72AfbCE4B128Bb0D62a41Eb`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.data.length) {
            await setItemFeatured(response.data);
            setValues({
              nft: response.data[selectedItemFeatured].tokenId,
              image: response.data[selectedItemFeatured].image,
              attributes: response.data[selectedItemFeatured].attributes,
            })
          }
        })
        .catch(async (error) => {
          console.log(error.response);
        });
    }
  };

  const getInventorys = async () => {
    await axios({
      method: "get",
      url: `https://app.themoopians.io/api/inventorys`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        let itemsShopData = response.data.data;
        if (itemsShopData.length) {
          const getIndexByTraitType = (items, traitType) => {
            return items.findIndex(item => item.layer === traitType);
          };

          const hairsIndex = getIndexByTraitType(itemsShopData, "Hair");

          if (hairsIndex !== -1) {
            const hairs = itemsShopData[hairsIndex];

            itemsShopData.splice(hairsIndex, 1);
            itemsShopData.push(hairs);
          }
        }
        await setItemsShop(itemsShopData);
      })
      .catch(async (error) => {
        console.log(error.response);
      });
  };

  const getUserInventorys = async () => {
    await axios({
      method: "get",
      url: `https://app.themoopians.io/api/userInventory/${loginUserAddress}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.data.data.length) await setUserItems(response.data.data);
      })
      .catch(async (error) => {
        console.log(error.response);
      });
  };

  const buy = async (layer, trait) => {
    await setLoading(true);
    try {
      await axios({
        method: "post",
        url: "https://app.themoopians.io/api/buy",
        // url: "http://localhost:8081/buy",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          wallet: loginUserAddress,
          // wallet: '0xa6E272F6942Dd501D72AfbCE4B128Bb0D62a41Eb',
          layer: layer,
          trait: trait
        }
      })
        .then(async (response) => {
          if (response.data.success) {
            setSuccess(response.data.data);
          } else {
            setError(response.data.data);
          }
          getUserInventorys();
          getBalance(loginUserAddress);
          await setLoading(false);
          setTimeout(() => {
            setSuccess("");
            setError("");
          }, 5000);
        })
        .catch(async (error) => {
          console.log(error.response);
          await setLoading(false);
        });
    } catch (error) {
      console.log(error.response);
      await setLoading(false);
    }
  };

  const load = async () => {
    await setLoading(true);
    await getInventorys();
    await getUserInventorys();
    await getNFTs();
    await setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (itemFeatured.length) {
      setValues({
        nft: itemFeatured[selectedItemFeatured].tokenId,
        image: itemFeatured[selectedItemFeatured].image,
        attributes: itemFeatured[selectedItemFeatured].attributes,
      })
    }
  }, [selectedItemFeatured]);

  const getBalance = async (address) => {
    await axios({
      method: "get",
      url: `https://app.themoopians.io/api/getBalance/${address}`,
      // url: `https://app.themoopians.io/api/getBalance/0xa6E272F6942Dd501D72AfbCE4B128Bb0D62a41Eb`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.data.data) setBalance(response.data.data);
        else setBalance(0);
      })
      .catch(async (error) => {
        console.log(error.response);
        setBalance(0);
      });
  };

  useEffect(() => {
    getBalance(loginUserAddress);
  }, []);

  return (
    <>
      <div className="font-Poppins flex justify-end max-w-[1920px] mx-auto">
        <div className="bg-[#F8F8F8] min-h-screen w-full lg:w-[80%] 2xl:w-[85%] dark:bg-slate-700 pt-4 sm:pt-8 px-4 sm:px-8 space-y-8 pb-10">
          <Header userBalance={userBalance} setSearch={setSearch} search={search} placeholder="Search Item and Traits.." />
          <div className="lg:hidden flex justify-between items-center">
            <button className={`w-[48%] border-2 border=[#E6007C] py-[12px] font-medium text-[16px] ${tab === 2 ? "bg-white text-[#E6007C]" : 'text-white bg-[#E6007C]'}`} onClick={() => setTab(1)}>Shop</button>
            <button className={`w-[48%] border-2 border=[#E6007C] py-[12px] font-medium text-[16px] ${tab === 1 ? "bg-white text-[#E6007C]" : 'text-white bg-[#E6007C]'}`} onClick={() => setTab(2)}>Inventory</button>
          </div>

          <div className={`lg:w-[60%] w-full ${!(tab === 1) ? "lg:block md:block hidden" : null}`}>
            <ul className="flex flex-row-reverse justify-end items-center text-sm text-[#7A797D] mt-2">
              {filteredItemsShop.length
                ? filteredItemsShop.map((data, index) => (
                  <li
                    key={index}
                    onClick={() => handleItemClick(index)}
                    className={`${selectedItem === index
                      ? "text-[#E6007C] bg-[#E6007C] bg-opacity-10 px-3 py-[5px] rounded-[30px]"
                      : "cursor-pointer px-3 py-[5px] rounded-[30px]"
                      }`}
                  >
                    {data.layer}
                  </li>
                ))
                : null
              }
            </ul>
          </div>

          <div className="flex flex-wrap">
            <div className={`lg:w-[60%] w-full ${!(tab === 1) ? "lg:block md:block hidden" : null}`}>
              <div className="flex max-sm:justify-center flex-wrap gap-y-6 sm:gap-2 md:gap-3 lg:gap-6 my-6">
                {filteredItemsShop.length
                  ? filteredItemsShop.map((data, item) => (
                    selectedItem == item
                      ? data.trait
                        .filter(trait => {
                          const regex = new RegExp(search, "i");
                          const isTraitMatch = regex.test(trait.trait);
                          const isDeadlineValid = trait.deadline === "unlimited" || trait.deadline === null || trait.deadline === undefined || new Date(trait.deadline) > new Date();
                          const isQuantityAvailable = trait.quantity === "unlimited" || trait.quantity === null || trait.quantity === undefined || (trait.quantity - trait.sold > 0);

                          return isTraitMatch && isDeadlineValid && isQuantityAvailable;
                        }).length
                        ? data.trait
                          .filter(trait => {
                            const regex = new RegExp(search, "i");
                            const isTraitMatch = regex.test(trait.trait);
                            const isDeadlineValid = trait.deadline === "unlimited" || trait.deadline === null || trait.deadline === undefined || new Date(trait.deadline) > new Date();
                            const isQuantityAvailable = trait.quantity === "unlimited" || trait.quantity === null || trait.quantity === undefined || (trait.quantity - trait.sold > 0);

                            return isTraitMatch && isDeadlineValid && isQuantityAvailable;
                          })
                          .map((trait, index) => (
                            <div
                              key={index}
                              className="group relative rounded-2xl bg-white p-3 w-[50%] sm:w-[49%] md:w-[32%] lg:w-[30%] "
                            >
                              <div className="w-full rounded-xl max-sm:flex items-center justify-center">
                                <img
                                  src={`https://app.themoopians.io/api/images/${data.layer}/${trait.trait}.png`}
                                  alt={trait.trait}
                                  className="h-[180px] object-cover rounded-2xl w-full"
                                />
                              </div>

                              <div className="mx-3 mt-3 mb-2">
                                <h2 className="text-[16px] text-[#27262E] font-semibold">
                                  {trait.trait}
                                </h2>
                              </div>
                              <div className="flex justify-between items-center mx-3">
                                <div className="flex items-center space-x-2">
                                  <p className="text-[#E6007C] text-sm font-semibold">
                                    {trait.price}
                                  </p>
                                  <p className="text-[#27262E] text-xs font-medium">
                                    {" "}
                                    MooLah
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-between items-center mx-3">
                                <div className="flex items-center space-x-2">
                                  <p className="text-[#27262E] text-xs font-medium">
                                    {`Pieces Left: `}
                                    <span className="text-[#E6007C] font-semibold text-sm">
                                      {trait.quantity === "unlimited" || trait.quantity === null || trait.quantity === undefined
                                        ? "Unlimited"
                                        : `${trait.quantity - trait.sold} pieces left`}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-between items-center mx-3">
                                <div className="flex items-center space-x-2">
                                  <p className="text-[#27262E] text-xs font-medium">
                                    {`Days Left: `}
                                  </p>
                                  <p className="text-[#E6007C] text-sm font-semibold">
                                    {trait.deadline === "unlimited" || trait.deadline === null || trait.deadline === undefined
                                      ? "No Deadline"
                                      : `${Math.max(0, Math.ceil((new Date(trait.deadline) - new Date()) / (1000 * 60 * 60 * 24)))} days left`}
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-center items-center bg-[#000000B2] h-full w-full opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute top-0 left-0 rounded-2xl">
                                {!(userItems.some((item) => item.layer === data.layer && item.traits.includes(trait.trait)))
                                  ? <button className="text-lg h-[36px] text-white font-medium w-[80%] bg-[#E6007C] rounded-lg " onClick={() => buy(data.layer, trait.trait)}>
                                    Buy
                                  </button>
                                  : <button className="text-lg py-2 text-white font-medium w-[80%] bg-green-600 rounded-lg ">
                                    Already Purchased
                                  </button>
                                }
                              </div>
                            </div>
                          ))
                        : <p>No trait match</p>
                      : null
                  ))
                  : <div className="text-center w-full">No Moopians found</div>}
              </div>
            </div>
            <div className={`lg:w-[40%] w-full rounded-2xl bg-white p-4 mt-5 ${!(tab === 2) ? "lg:block md:block hidden" : null}`}>
              {itemFeatured.length ? (
                <>
                  <div className="relative">
                    <img
                      src={values.image.replace(
                        "gray-total-ferret-825.mypinata.cloud",
                        "ipfs.io"
                      )}
                      alt=""
                      className="w-full rounded-2xl"
                    />
                    <div className="absolute bottom-2 right-2 cursor-pointer">
                      <div className="relative inline-block text-left">
                        <div>
                          <button
                            type="button"
                            className="flex space-x-2 items-center rounded-[40px] text-[16px] font-semibold border-[2px] text-white border-[#E9E9E9] bg-[#FFFFFF33] px-4 py-2"
                            id="menu-button"
                            aria-expanded="true"
                            aria-haspopup="true"
                            onClick={() => setdropdown(!dropdown)}
                          >
                            <span>{itemFeatured[selectedItemFeatured]?.name}</span>
                            <LiaAngleDownSolid />
                          </button>
                        </div>

                        {dropdown ? (
                          <div
                            ref={dropdownRef}
                            className="absolute right-0 z-10 mt-2 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                            tabindex="-1"
                          >
                            <div className="py-1" role="none">
                              {itemFeatured.map((data, index) => (
                                <p className="px-4 py-2 font-medium hover:text-[#E6007C] hover:bg-[#E6007C] hover:bg-opacity-10" key={index} onClick={() => { setSelectedItemFeatured(index); setdropdown(!dropdown); }}>
                                  {data.name}
                                </p>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <button onClick={saveNft} className="text-lg px-10 mt-5 py-3 text-white font-medium inline-block bg-[#E6007C] rounded-lg ">
                Save
              </button>
              <div className="mt-7">
                <p className="font-normal ml-2 text-lg mb-2">My Inventory</p>
                <div>
                  <ul className="flex items-center text-xs text-[#7A797D] mt-2">
                    {userItems.length 
                      ?userItems.map((data, index) => (
                        <li
                          key={index}
                          onClick={() => setSelectedUserItem(index)}
                          className={`${selectedUserItem === index
                            ? "text-[#E6007C] bg-[#E6007C] bg-opacity-10 px-3 py-[5px] rounded-[30px]"
                            : "cursor-pointer px-3 py-[5px] rounded-[30px]"
                            }`}
                        >
                          {data.layer}
                        </li>
                      ))
                      :null
                    }
                  </ul>
                </div>
                <div className="flex max-sm:justify-center flex-wrap gap-y-6 sm:gap-2 md:gap-3 lg:gap-3 my-5 h-[350px] overflow-y-scroll">
                  {userItems.length 
                    ?userItems.map((data, item) => (
                      data.traits.map((trait, index) => (
                        selectedUserItem == item ? (
                          <div
                            key={index}
                            onClick={() => setAttributes(trait, data.layer)}
                            className="relative rounded-2xl bg-white p-3 w-[50%] sm:w-[49%] md:w-[32%] lg:w-[30%] "
                          >
                            <div className="w-full rounded-xl max-sm:flex items-center justify-center">
                              <img
                                src={`https://app.themoopians.io/api/images/${data.layer}/${trait}.png`}
                                alt={trait}
                                className="h-[150px] object-cover rounded-2xl shadow-2xl"
                              />
                            </div>
                          </div>
                        ) : null
                      ))
                    ))
                    :null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {error ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-red-600 rounded-2xl text-white font-semibold">{error}</div> : null}
      {success ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-green-600 rounded-2xl text-white font-semibold">{success}</div> : null}
      {loading
        ? <div className="fixed  right-0 top-0  transform flex justify-center items-center w-full min-h-screen z-50 bg-[rgba(0,0,0,0.2)]">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-[#E6007C] border-8 h-52 w-52"></div>
        </div>
        : null
      }
    </>
  );
}
