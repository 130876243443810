import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import DetailsMoopian from "../components/DetailsMoopian";
import axios from "axios";

const ImageLoader = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);

  // const convertedUrl = src.replace(
  //   "gray-total-ferret-825.mypinata.cloud",
  //   "ipfs.io"
  // );

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="relative">
      {isLoading && (
        <div className="h-[190px] w-full flex items-center">
          <img src="/img/Logo.png" alt={""} className="rounded-2xl" />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        onLoad={handleLoad}
        className={
          isLoading
            ? "hidden"
            : "block h-[190px] w-full object-cover rounded-2xl"
        }
      />
    </div>
  );
};

export default function Moopians(props) {
  // eslint-disable-next-line
  let loginUserAddress = sessionStorage.getItem("address");
  const [itemsMoopian, setItemsMoopian] = useState([]);
  const [isDetail, setIsDetail] = useState(false);
  const [details, setDetails] = useState({});
  const [userBalance, setBalance] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const regex = new RegExp(search, 'i');
  const filteredMoopians = itemsMoopian.filter((data) =>
    regex.test(data.name)
  );

  const getNFTs = async () => {
    setLoading(true);
    if (loginUserAddress) {
      await axios({
        method: "get",
        url: `https://app.themoopians.io/api/moopians/${loginUserAddress}`,
        // url: `https://app.themoopians.io/api/moopians/0x1830C9B1A7a0B411B90f06bf13965cd6c29E317a`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          console.log(response.data);
          await setItemsMoopian(response.data);
          setLoading(false);
        })
        .catch(async (error) => {
          console.log(error.response);
          setLoading(false);
        });
    }
  };

  const showDetails = (data) => {
    setIsDetail(true);
    setDetails(data);
  };

  useEffect(() => {
    getNFTs();
  }, []);

  const getBalance = async (address) => {
    await axios({
      method: "get",
      url: `https://app.themoopians.io/api/getBalance/${address}`,
      // url: `http://localhost:8081/getBalance/0xa6E272F6942Dd501D72AfbCE4B128Bb0D62a41Eb`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        console.log(response.data.data);
        if (response.data.data) setBalance(response.data.data);
        else setBalance(0);
      })
      .catch(async (error) => {
        console.log(error.response);
        setBalance(0);
      });
  };

  useEffect(() => {
    getBalance(loginUserAddress);
  }, []);

  return (
    <>
      <div className="font-Poppins flex justify-end max-w-[1920px] mx-auto">
        <div className="bg-[#F8F8F8] min-h-screen w-full lg:w-[80%] 2xl:w-[85%] dark:bg-slate-700 py-4 sm:pt-8 px-4 sm:px-8 space-y-8">
          <Header userBalance={userBalance} setSearch={setSearch} search={search} placeholder="Search Moopians.." />

          {isDetail ? (
            <DetailsMoopian data={details} setIsDetail={setIsDetail} />
          ) : (
            <div className="flex max-sm:justify-center flex-wrap gap-y-6 sm:gap-2 md:gap-3 lg:gap-6 my-6">
              {filteredMoopians.length
                ? filteredMoopians.map((data, index) => (
                  <div
                    key={index}
                    className="rounded-2xl bg-white p-3 space-y-6 w-full sm:w-[49%] md:w-[32%] lg:w-[30%] 2xl:w-[32%]"
                    onClick={() => showDetails(data)}
                  >
                    <div className="w-full rounded-xl max-sm:flex items-center justify-center">
                      <ImageLoader src={data.image} alt={data.heading} />
                    </div>

                    <div className="ml-3 space-y-[6px] pb-4">
                      <h2 className="text-2xl text-[#27262E] font-semibold">
                        {data.name}
                      </h2>
                      <p className="text-[#747475] overflow-hidden line-clamp-4">{data.description}</p>
                    </div>
                  </div>
                ))
                : <div className="text-center w-full">No Moopians found</div>}
            </div>
          )}
        </div>
      </div>
      {loading
        ? <div className="fixed  right-0 top-0  transform flex justify-center items-center w-full min-h-screen z-50 bg-[rgba(0,0,0,0.2)]">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-[#E6007C] border-8 h-52 w-52"></div>
        </div>
        : null
      }
    </>
  );
}
